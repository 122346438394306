import React, { useContext, useEffect, useState } from "react";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Box from "@mui/material/Box";
import Stepone from "./stepone";
import Steptwo from "./steptwo";
import useAlert from "../../hooks/useAlert";
import { useMutation, useQuery } from "@apollo/client";

import { convertToISOString } from "../helperFunctions";
import { ProfileContext } from "../../context/profileContext";
import { AppContext } from "../../App";
import { useFetchAPI } from "../../api/axios/axios";
import { useNavigate } from "react-router-dom";

const user =
  localStorage.getItem("profile") == "undefined"
    ? ""
    : JSON.parse(localStorage.getItem("profile"));

export default function Drawer({
  drawer,
  setDrawer,
  handleDrawerOpen,
  doc,
  selectedDate,
}) {
  const { displayAlert } = useAlert();
  const { loading: appLoading, setLoading: setAppLoading } =
    useContext(AppContext);
  const { profile } = useContext(ProfileContext);

  const [confirmDrawer, setConfirmDrawer] = useState(false);
  const [loading, setLoading] = useState(false);

  const [plansLoading, setPlansLoading] = useState(false);
  const [plansError, setPlansError] = useState(null);
  const [plansData, setPlansData] = useState([]);

  const [time, setTime] = useState("");
  const [channel, setChannel] = useState("");
  const [symptoms, setSymptoms] = useState("");
  const [level, setLevel] = useState("");
  const [expect, setExpect] = useState("");
  const [aob, setAob] = useState("");
  const [successDrawer, setSuccessDrawer] = useState(false);
  const [fee, setFee] = useState("");
  const [plan, setPlan] = useState(null);
  const [batches, setBatches] = useState([]);

  const [age, setAge] = useState("");
  const [weight, setWeight] = useState("");
  const [height, setHeight] = useState("");

  const callbackUrl = process.env.REACT_APP_FRONTEND_URL;

  const prov = JSON.parse(localStorage.getItem("profile"));
  const providerId = localStorage.getItem("providerId");
  const authToken = localStorage.getItem("authToken");
  const apiKey = localStorage.getItem("apiKey");
  const patientId = profile?.profiles?.data[0]?._id;
  const email = localStorage.getItem("userEmail");
  const isoString = convertToISOString(selectedDate, time);
  const { CREATE_CONSULTATION, GET_PLANS, CREATE_PAYMENT_URL } = useFetchAPI();
  const hmoID = localStorage.getItem("hmoID");
  const navigate = useNavigate();

  const user =
    localStorage.getItem("profile") == "undefined"
      ? ""
      : JSON.parse(localStorage.getItem("profile"));

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        setPlansLoading(true);
        const response = await GET_PLANS(providerId, apiKey);
        setPlansData(response.data.plan[0].amount);
        console.log(response, "response.data");
        setPlan(response.data.plan[0].amount);
        setFee(response.data.plan[0].amount);
      } catch (error) {
        console.log(error, "response.data");

        setPlansError(error);
      } finally {
        setPlansLoading(false);
      }
    };

    fetchPlans();
  }, [fee]);

  const createConsultation = async () => {
    let uniqueProvider =
  process.env.REACT_APP_PROVIDER_KEY_Khairo === "66e000f0a983160013ca8d38"
    ? true
    : false;

    function convertTime(inputTime) {
      const [year, month, day] = inputTime.split("-"); 
      const hours = "00"; // Set hours to '00'
      const minutes = "00"; // Set minutes to '00'

      // Create a new date string in the desired format
      const dateString = `${year}-${month}-${day}T${hours}:${minutes}:00+01:00`;

      return dateString;
    }

    const formattedTime = convertTime(selectedDate);
    const data = user?.profiles?.data[0];

    console.log(time,selectedDate, "useruser");
    try {
      setLoading(true);
      setAppLoading(true);
    
      const patientData = {
        email: user?.enrollee?.email || email || "",
        firstName: user?.enrollee?.name
            ? user?.enrollee?.firstName || ""
            : user?.firstName || data?.firstName || "",
        lastName: user?.enrollee?.name
            ? user?.enrollee?.lastName || ""
            : user?.lastName || data?.lastName || "",
        phoneNumber: user?.enrollee?.phone
            ? user?.enrollee?.phone || ""
            : user?.phoneNumber || data?.phoneNumber || "",
        gender: user?.gender || data?.phoneNumber,
        image: "",
        height: uniqueProvider ? height : "0",
        weight: uniqueProvider ? weight : "0",
        bloodGroup: "",
        genotype: "",
        // Conditionally add hmoId if it's not empty
        ...(user?.enrollee?.hmoId && user.enrollee.hmoId !== "false"
          ? { hmoId: user.enrollee.hmoId }
          : hmoID && hmoID !== "false"
          ? { hmoId: hmoID }
          : {})
      };
    
      const consultationVar = {
        consultationOwner: "myself",
        patientData,
        doctor: doc._id,
        discomfortLevel: uniqueProvider ? "Nil" : level,
        description: aob,
        firstNotice: uniqueProvider ? "Nil" : expect,
        providerId: providerId,
        contactMedium: channel,
        time: `${selectedDate}T${time}:00+01:00`,
        symptoms: uniqueProvider
          ? [{ name: "Nil" }]
          : batches.length > 0 && symptoms.length > 0
          ? [...batches.map((batch) => ({ name: batch })), { name: symptoms }]
          : batches.length > 0
          ? batches.map((batch) => ({ name: batch }))
          : symptoms.length > 0
          ? [{ name: symptoms }]
          : [],
        type: "scheduled",
        providerId: providerId,
        timeOffset: "",
        status: "pending",
        createdThrough: "doctor-direct",
      };
    
      const res = await CREATE_CONSULTATION(consultationVar);
      localStorage.setItem("consultationInfo", JSON.stringify(res?.data?.data));
    
      if (hmoID === "false") {
        setLoading(false);
        const paymentUrlResponse = await CREATE_PAYMENT_URL({
          email: email,
          reason: "consultation",
          itemId: res?.data?.data?._id,
          callbackUrl: `${callbackUrl}doctors/consultsuccess`,
          amount: doc?.fee,
        });
        const reference = paymentUrlResponse?.data?.data?.reference;
        const url = paymentUrlResponse?.data?.data?.authorization_url;
        localStorage.setItem("psRef", reference);
        window.open(url, "_self");
        setAppLoading(false);
      } else {
        setAppLoading(false);
        navigate(`/doctors/consultsuccess`);
      }
    }
    catch (error) {
      displayAlert("error", "Initiating consultation failed!");
      console.error("Failed to create consultation", error);
      setAppLoading(false);
      setLoading(false);
    }
  };

  const handleReset = () => {
    setTime("");
    setChannel("");
    setBatches([]);
    setLevel("");
    setExpect("");
    setAob("");
  };

  const handleConfirmDrawerOpen = () => {
    setConfirmDrawer(true);
  };

  const handleConfirmDrawerClose = () => {
    setConfirmDrawer(false);
  };

  const handleDrawerClose = () => {
    setDrawer(false);
  };

  return (
    <SwipeableDrawer
      anchor="right"
      open={drawer}
      onClose={() => setDrawer(false)}
      onOpen={handleDrawerOpen}
    >
      <Box
        sx={{
          width: {
            xs: "100%",
            sm: 582,
          },
        }}
        role="presentation"
      >
        <Stepone
          doc={doc}
          handleDrawerClose={handleDrawerClose}
          selectedDate={selectedDate}
          time={time}
          channel={channel}
          setTime={setTime}
          setChannel={setChannel}
          setSymptoms={setSymptoms}
          setLevel={setLevel}
          setExpect={setExpect}
          setAob={setAob}
          symptoms={symptoms}
          batches={batches}
          setBatches={setBatches}
          aob={aob}
          level={level}
          expect={expect}
          setFee={setFee}
          setConfirmDrawer={setConfirmDrawer}
          confirmDrawer={confirmDrawer}
          handleReset={handleReset}
          age={age}
          setAge={setAge}
          weight={weight}
          setWeight={setWeight}
          height={height}
          setHeight={setHeight}
        />

        <SwipeableDrawer
          anchor="right"
          open={confirmDrawer}
          onClose={() => {
            setConfirmDrawer(false);
            setDrawer(false);
          }}
          onOpen={handleConfirmDrawerOpen}
        >
          <Box
            sx={{
              width: {
                xs: "100%",
                sm: 582,
              },
            }}
            role="presentation"
          >
            <Steptwo
              handleConfirmDrawerClose={handleConfirmDrawerClose}
              doc={doc}
              selectedDate={selectedDate}
              time={time}
              channel={channel}
              level={level}
              expect={expect}
              aob={aob}
              batches={batches}
              symptoms={symptoms}
              successDrawer={successDrawer}
              setSuccessDrawer={setSuccessDrawer}
              setConfirmDrawer={setConfirmDrawer}
              setDrawer={setDrawer}
              handleReset={handleReset}
              fee={fee}
              createConsultation={createConsultation}
              loading={loading}
              age={age}
              setAge={setAge}
              weight={weight}
              setWeight={setWeight}
              height={height}
              setHeight={setHeight}
            />

            {/* <SwipeableDrawer
                            anchor="right"
                            open={successDrawer}
                            onClose={() => {
                                setSuccessDrawer(false);
                                setConfirmDrawer(false);
                                setDrawer(false);
                            }}
                            onOpen={() => setSuccessDrawer(true)}
                            sx={{
                                width: {
                                    xs: '100%',
                                }
                            }}
                        >
                            <Box
                                sx={{ 
                                    width: {
                                        xs: '100%',
                                        sm: 600
                                    },
                                    height: '100%'
                                }}
                                role="presentation"
                            >
                                <Stepthree 
                                    setSuccessDrawer={setSuccessDrawer}
                                    setConfirmDrawer={setConfirmDrawer}
                                    setDrawer={setDrawer}
                                    handleReset={handleReset}
                                    doc={doc}
                                />
                            </Box>
                        </SwipeableDrawer> */}
          </Box>
        </SwipeableDrawer>
      </Box>
    </SwipeableDrawer>
  );
}
