import React from 'react'

export default function Menubox({children, onClicked, activeItem, setMenu}) {

    console.log(setMenu)
    
    const handleClick = () => {
        onClicked();
        setMenu(false);
    };
  
    return (
        <div
            className={
                `flex gap-4 p-4 cursor-pointer ${activeItem ? 'leftbox-clicked' : ''}`
            }
            onClick={handleClick}
        >
            {children}
        </div>
    );
}
