import axios from "axios";
import { useContext } from "react";
import { AppContext } from "../../App";

export const useFetchAPI = () => {
  const BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const { token } = useContext(AppContext);
  const storedToken = localStorage.getItem("apiKey");
  const storedKey = localStorage.getItem("authToken");

  const base = axios.create({
    baseURL: BASE_URL + "rest/",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Api-Key ${storedToken}`,
      Accept: "application/json",
    },
  });

  const GET_PLANS = (providerId, apiKey) =>
    base.get(`plans?filterBy[provider]=${providerId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Api-Key ${apiKey}`,
        Accept: "application/json",
      },
    });

  const GET_AVAILABLE_DOCTORS_BY_DATE = (date, providerId) =>
    base.get(`doctors?date=${date}&filterBy[providerId]=${providerId}`);

  const GET_DOCTOR_AVAILIBILITY_FOR_DATE = (date, doctorId) =>
    base.get(`doctors/availibility-for-date?date=${date}&doctor=${doctorId}`);
// for hospital consultation
  const CREATE_CONSULTATION = (body) => base.post(`consultations/hospital/create`, body);

  const VALIDATE_EMAIL = (body) => base.post(`consultations/validate/email`, body);
  
  const CREATE_PAYMENT_URL = (body) => base.post(`payments`, body);

  const CHECK_PAYMENT = (paymentRef) =>
    base.post(`payments/check-charge`, { reference: paymentRef });

  const GET_CONSULTATION_INFO = (id) => base.get(`consultations/${id}`);

  return {
    GET_PLANS,
    CHECK_PAYMENT,
    CREATE_PAYMENT_URL,
    CREATE_CONSULTATION,
    GET_CONSULTATION_INFO,
    VALIDATE_EMAIL,
    GET_AVAILABLE_DOCTORS_BY_DATE,
    GET_DOCTOR_AVAILIBILITY_FOR_DATE,
  };
};
